import React, { Fragment } from 'react'
import { css } from 'styled-components'
import { TABLET_MEDIA_QUERY } from 'typography-breakpoint-constants'

import List from './list'
import ListItem from './list-item'

type Item = {
  name: string
  values?: string | string[]
}

type Props = {
  items: Item[]
}

const primary = css`
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 0.88rem;
  color: var(--medium-grey-color);
  letter-spacing: 0.6px;

  &::before {
    content: '/';
    position: absolute;
    transform: translate(-0.5rem, 0);
  }
`

const secondary = css`
  color: var(--black-color);
  font-size: 0.7rem;
  line-height: 0.88rem;
  font-weight: 500;

  ul li:first-of-type {
    margin-top: 4px;
  }

  &:not(:last-of-type) ul li:last-of-type {
    margin-bottom: 24px;

    ${TABLET_MEDIA_QUERY} {
      margin-bottom: 20px;
    }
  }
`

const NestedList: React.FC<Props> = ({ items }) => (
  <List>
    {items.map(({ name, values }) => (
      <Fragment key={name}>
        <ListItem css={primary}>{name.toUpperCase()}</ListItem>
        {values && (
          <ListItem css={secondary}>
            <List>
              {(Array.isArray(values) ? values : [values]).map((value) => (
                <ListItem key={value}>{value}</ListItem>
              ))}
            </List>
          </ListItem>
        )}
      </Fragment>
    ))}
  </List>
)

export default NestedList

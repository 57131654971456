import { useLayoutEffect, useRef, useCallback } from 'react'

import { isClient } from '../utils'

export default function useOnWindowSize(cb?: (size: WindowSize) => void): void {
  const size = useRef<WindowSize>({
    width: 0,
    height: 0,
  })

  const handleResize = useCallback(() => {
    if (!isClient) {
      return
    }

    size.current = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    if (cb) {
      cb(size.current)
    }
  }, [cb, isClient])

  useLayoutEffect(() => {
    if (!isClient) {
      return
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])
}

export type WindowSize = {
  width: number
  height: number
}

import React, { ReactHTML } from 'react'
import { filterXSS } from 'xss'

type Props = {
  content: string
  className?: string
  type?: keyof ReactHTML
}

const SafeHTML: React.FC<Props> = ({ content, type = 'div', className }) =>
  React.createElement(type, {
    className,
    dangerouslySetInnerHTML: { __html: filterXSS(content) },
  })

export default SafeHTML

import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { useTransition, a, config } from 'react-spring'

import theme from '../utils/theme'
import useOnWindowSize, { WindowSize } from '../hooks/use-window-size'
import GlobalStyle from './global-style'
import ErrorBoundary from './error-boundary'

type Props = {
  location: Location
  seo?: GatsbyTypes.WpSEOFragmentFragment
}

const Layout: React.FC<Props> = ({ location, children, seo }) => {
  const transitions = useTransition(location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
  })

  useOnWindowSize((size: WindowSize) =>
    document.documentElement.style.setProperty(
      '--vh',
      `${size.height * 0.01}px`
    )
  )

  return (
    <StaticQuery<GatsbyTypes.LayoutQueryQuery>
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              siteUrl
            }
          }
          wp {
            generalSettings {
              title
              description
            }
          }
        }
      `}
      render={(data) => {
        const settings = data?.wp?.generalSettings
        const siteUrl = data.site?.siteMetadata?.siteUrl
        const description = seo?.metaDesc || settings?.description

        return (
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Helmet defaultTitle={settings?.title}>
                <html lang="fr" />
                {seo?.title && <title>{seo.title}</title>}
                {description && (
                  <meta name="description" content={description} />
                )}

                {/* OpenGraph */}
                {seo?.opengraphType && (
                  <meta property="og:type" content={seo.opengraphType} />
                )}
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:url" content={siteUrl + location.pathname} />
                {(seo?.opengraphTitle || seo?.twitterTitle || seo?.title) && (
                  <meta
                    property="og:title"
                    content={
                      seo.opengraphTitle || seo.twitterTitle || seo.title
                    }
                  />
                )}
                {(seo?.opengraphDescription ||
                  seo?.twitterDescription ||
                  seo?.metaDesc) && (
                  <meta
                    property="og:description"
                    content={
                      seo.opengraphDescription ||
                      seo.twitterDescription ||
                      seo.metaDesc
                    }
                  />
                )}
                {(seo?.opengraphImage?.localFile?.publicURL ||
                  seo?.twitterImage?.localFile?.publicURL) && (
                  <meta
                    property="og:image"
                    content={[
                      siteUrl,
                      seo.opengraphImage?.localFile?.publicURL ||
                        seo.twitterImage?.localFile?.publicURL,
                    ].join('')}
                  />
                )}
                {(seo?.opengraphImage?.mimeType ||
                  seo?.twitterImage?.mimeType) && (
                  <meta
                    property="og:image:type"
                    content={(seo.opengraphImage || seo.twitterImage)?.mimeType}
                  />
                )}
                {(seo?.opengraphImage?.mediaDetails?.width ||
                  seo?.twitterImage?.mediaDetails?.width) && (
                  <meta
                    property="og:image:width"
                    content={(
                      seo.opengraphImage || seo.twitterImage
                    )?.mediaDetails?.width?.toString()}
                  />
                )}
                {(seo?.opengraphImage?.mediaDetails?.height ||
                  seo?.twitterImage?.mediaDetails?.height) && (
                  <meta
                    property="og:image:height"
                    content={(
                      seo.opengraphImage || seo.twitterImage
                    )?.mediaDetails?.height?.toString()}
                  />
                )}
                {/* Twitter */}
                <meta name="twitter:card" content="summary" />
                {siteUrl && <meta name="twitter:site" content={siteUrl} />}
                {seo?.twitterTitle && (
                  <meta name="twitter:title" content={seo.twitterTitle} />
                )}
                {seo?.twitterDescription && (
                  <meta
                    name="twitter:description"
                    content={seo.twitterDescription}
                  />
                )}
                {seo?.twitterImage?.localFile?.publicURL && (
                  <meta
                    name="twitter:image"
                    content={[
                      siteUrl,
                      seo.twitterImage.localFile.publicURL,
                    ].join('')}
                  />
                )}
                {seo?.twitterImage?.altText && (
                  <meta
                    name="twitter:image:alt"
                    content={seo.twitterImage.altText}
                  />
                )}
              </Helmet>

              <Wrapper>
                {transitions((style) => (
                  // @ts-expect-error: Error with react-spring
                  <a.div style={style}>{children}</a.div>
                ))}
              </Wrapper>
            </ThemeProvider>
          </ErrorBoundary>
        )
      }}
    />
  )
}

export default Layout

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--vh));
`

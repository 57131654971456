import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import GatsbyImage, { FluidObject } from 'gatsby-image'

type Props = {
  fluid: FluidObject
  title?: string
  className?: string
  fadeIn?: boolean
  loading?: `auto` | `lazy` | `eager`
}

const style = { width: '100%', height: '100%', overflow: 'hidden' }

const Image: React.FC<Props> = ({
  className,
  title,
  fluid,
  fadeIn = true,
  loading = 'lazy',
}) => {
  const context = useContext(ThemeContext)

  return (
    <GatsbyImage
      style={style}
      className={className}
      title={title}
      fluid={fluid}
      backgroundColor={context.colors.primary}
      fadeIn={fadeIn}
      loading={loading}
      itemProp="url"
    />
  )
}

export default Image

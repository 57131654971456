import { Movie, Person } from 'schema-dts'
import { BaseProject } from '../@types/core'

export const isClient: boolean = typeof window === 'object'

export function computeProjectPathname(slug: string): string {
  return `/projects/${slug}/`
}

export function renderMovieJsonLdProp(project: BaseProject): Movie {
  return {
    '@type': 'Movie',
    name: project.title,
    description: project.description,
    dateCreated: project.date,
    image: project.thumbnail.fluid.src,
    inLanguage: 'fr',
    director: project.directors.map(renderPersonJsonLdProp),
    author: project.authors.map(renderPersonJsonLdProp),
  }
}

const ME = 'ida borie'
export function renderPersonJsonLdProp(name: string = ME): Person {
  return {
    '@type': 'Person',
    ...(name.toLowerCase().trim().includes(ME)
      ? { sameAs: process.env.SITE_URL }
      : {}),
    name: name,
  }
}

export function pluralize(name: string, count: number): string {
  return `${name}${count > 1 ? 's' : ''}`
}

export function clamp(x: number, min: number, max: number): number {
  return Math.max(min, Math.min(x, max))
}

export function isLowLuminanceColor(hex: string): boolean {
  const { R, G, B } = hexToRgb(hex)

  const C = [R / 255, G / 255, B / 255].map((c) =>
    c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
  )

  return 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2] <= 0.179
}

function hexToRgb(hex: string): { R: number; G: number; B: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    throw new ParsingColorError(hex)
  }

  return {
    R: parseInt(result[1], 16),
    G: parseInt(result[2], 16),
    B: parseInt(result[3], 16),
  }
}

export class ParsingColorError extends Error {
  constructor(color: string) {
    super(`Cannot parse HEX color ${color} to RGB`)
  }
}

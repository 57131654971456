import { createGlobalStyle } from 'styled-components'

import maaxNormalWoff2 from '../assets/fonts/maax-normal.woff2'
import maaxNormalWoff from '../assets/fonts/maax-normal.woff'
import maaxMediumWoff2 from '../assets/fonts/maax-medium.woff2'
import maaxMediumWoff from '../assets/fonts/maax-medium.woff'
import maaxMediumItalicWoff2 from '../assets/fonts/maax-medium-italic.woff2'
import maaxMediumItalicWoff from '../assets/fonts/maax-medium-italic.woff'
import maaxItalicWoff2 from '../assets/fonts/maax-italic.woff2'
import maaxItalicWoff from '../assets/fonts/maax-italic.woff'
import maaxBoldItalicWoff2 from '../assets/fonts/maax-bold-italic.woff2'
import maaxBoldItalicWoff from '../assets/fonts/maax-bold-italic.woff'
import maaxBoldWoff2 from '../assets/fonts/maax-bold.woff2'
import maaxBoldWoff from '../assets/fonts/maax-bold.woff'
import maaxBlackWoff2 from '../assets/fonts/maax-black.woff2'
import maaxBlackWoff from '../assets/fonts/maax-black.woff'

export default createGlobalStyle`
  @font-face {
    font-family: 'maax';
    src: url(${maaxBlackWoff2}) format('woff2'),
      url(${maaxBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxBoldWoff2}) format('woff2'),
      url(${maaxBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxBoldItalicWoff2}) format('woff2'),
      url(${maaxBoldItalicWoff}) format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxItalicWoff2}) format('woff2'),
      url(${maaxItalicWoff}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxMediumWoff2}) format('woff2'),
      url(${maaxMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxMediumItalicWoff2}) format('woff2'),
      url(${maaxMediumItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap
  }

  @font-face {
    font-family: 'maax';
    src: url(${maaxNormalWoff2}) format('woff2'),
      url(${maaxNormalWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap
  }

  :root {
    --primary-color: ${(props) => props.theme.colors.primary};
    --deco-color: linear-gradient(
      90deg,
      rgb(225, 16, 250) 0%,
      rgba(128, 29, 253, 1) 50%
    );
    --light-grey-color: #afafaf;
    --medium-grey-color: #737373;
    --dark-grey-color: #4c4c4c;
    --black-color: #000000;
    --white-color: #ffffff;
    --grid-column-count: 24;
    --grid-column-gap: 0.55rem;
    --footer-height: 50px;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .js-prevent-scroll-bounce {
    overflow: hidden;
  }
`

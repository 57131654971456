import { v4 as uuidv4 } from 'uuid'

import {
  ContentLayout,
  Image,
  Project,
  Property,
  Content,
  VideoContent,
  QuoteContent,
  ContentType,
  TextContent,
  ImageContent,
  BaseProject,
  ContentPosition,
} from './@types/core'

class ProjectParsingError extends Error {}

class MissingRequiredProperty extends ProjectParsingError {}
class InvalidProperty extends ProjectParsingError {}

export function projectMapper(
  project: GatsbyTypes.ProjectFieldsFragment,
  baseProjectFieldsFragments?: GatsbyTypes.BaseProjectFieldsFragment[]
): Project {
  let currentIndex = -1
  let nextWordpressProject
  if (baseProjectFieldsFragments) {
    currentIndex = baseProjectFieldsFragments.findIndex(
      (current) => current.id === project.id
    )

    const nextIndex = (currentIndex + 1) % baseProjectFieldsFragments.length

    nextWordpressProject = mapBaseProjectFieldsFragmentToBaseProject(
      baseProjectFieldsFragments[nextIndex],
      nextIndex
    )
  }

  return {
    ...mapBaseProjectFieldsFragmentToBaseProject(project, currentIndex),
    properties: mapACFProperties(project.acf?.properties),
    content: mapACFFieldsFragmentContent(project.acf?.content),
    next: nextWordpressProject,
  }
}

export function mapBaseProjectFieldsFragmentToBaseProject(
  project: GatsbyTypes.BaseProjectFieldsFragment,
  index: number
): BaseProject {
  if (!project.title) {
    throw new MissingRequiredProperty()
  }

  if (!project.acf?.general?.color) {
    throw new MissingRequiredProperty()
  }

  if (!project.slug) {
    throw new MissingRequiredProperty()
  }

  if (!project.acf?.description) {
    throw new MissingRequiredProperty()
  }

  if (!project.acf.general?.date) {
    throw new MissingRequiredProperty()
  }

  if (!project.acf?.thumbnail) {
    throw new MissingRequiredProperty()
  }

  return {
    id: project.id,
    title: project.title,
    description: project.acf.description,
    date: project.acf.general.date,
    directors: mapWpPersonToNames(project.acf.general.directors),
    authors: mapWpPersonToNames(project.acf.general.authors),
    color: project.acf.general.color,
    order: index + 1,
    slug: project.slug,
    roles: mapWpTagsToRoles(project.tags?.nodes),
    thumbnail: mapWpMediaItemToImage(project.acf.thumbnail),
  }
}

function mapWpPersonToNames(
  directors?: readonly GatsbyTypes.Maybe<Pick<GatsbyTypes.WpPerson, 'name'>>[]
): string[] {
  return (directors ?? []).reduce<string[]>(
    (acc, current) => (current?.name ? [...acc, current.name] : acc),
    []
  )
}

function mapWpTagsToRoles(
  tags?: readonly Partial<GatsbyTypes.Maybe<GatsbyTypes.WpTag>>[]
): string[] {
  return (tags ?? [])
    .reduce<string[]>(
      (acc, current) => (current?.name ? [...acc, current.name] : acc),
      []
    )
    .sort((a, b) => a.length - b.length)
}

function mapACFProperties(
  ACFProperties?: readonly GatsbyTypes.Maybe<
    Pick<GatsbyTypes.WpPost_Acf_properties, 'label' | 'value'>
  >[]
): Property[] {
  return (ACFProperties ?? []).reduce<Property[]>((acc, current) => {
    if (!current?.label || !current?.value) {
      return acc
    }
    return [
      ...acc,
      {
        name: current.label,
        values: current.value.split(',').map((value) => value.trim()),
      },
    ]
  }, [])
}

function mapACFFieldsFragmentContent(
  ACFFieldsFragment?: readonly GatsbyTypes.Maybe<ACFFieldsFragment>[]
): Content[] {
  return (ACFFieldsFragment ?? []).reduce<Content[]>((acc, current) => {
    if (!current) {
      return acc
    }
    let content: Content

    if (isACFImageFieldsFragment(current)) {
      content = mapACFImageFieldsFragmentToImageContent(current)
    } else if (isACFVideoFieldsFragment(current)) {
      content = mapACFVideoFieldsFragmentToVideoContent(current)
    } else if (isACFQuoteFieldsFragment(current)) {
      content = mapACFQuoteFieldsFragmentToQuoteContent(current)
    } else if (isACFTextFieldsFragment(current)) {
      content = mapACFTextFieldsFragmentToTextContent(current)
    } else {
      return acc
    }
    return [...acc, content]
  }, [])
}

export function mapACFVideoFieldsFragmentToVideoContent(
  content: GatsbyTypes.ACFVideoFieldsFragment
): VideoContent {
  if (!content.video) {
    throw new MissingRequiredProperty()
  }

  return {
    id: uuidv4(),
    type: ContentType.Video,
    layout: ContentLayout.Wide,
    video: content.video,
  }
}

export function mapACFImageFieldsFragmentToImageContent(
  content: GatsbyTypes.ACFImageFieldsFragment
): ImageContent {
  if (!content.layout) {
    throw new MissingRequiredProperty()
  }

  if (!content.position) {
    throw new MissingRequiredProperty()
  }

  const image = mapWpMediaItemToImage(content.image)
  const layout = mapContentLayout(content.layout)

  const hasDescription =
    content.displayDescription === true &&
    layout === ContentLayout.Column &&
    !!(image.description || image.caption)

  return {
    id: uuidv4(),
    type: ContentType.Image,
    layout: hasDescription ? ContentLayout.Fullscreen : layout,
    position: mapContentPosition(content.position),
    image: image,
    hasDescription: hasDescription,
  }
}

export function mapWpMediaItemToImage(
  mediaItem?: GatsbyTypes.WpMediaItemFragmentFragment
): Image {
  if (!mediaItem?.localFile?.childImageSharp?.fluid) {
    throw new MissingRequiredProperty()
  }
  return {
    title: mediaItem.title,
    description: mediaItem.description,
    caption: mediaItem.caption,
    fluid: mediaItem.localFile?.childImageSharp?.fluid,
  }
}

export function mapACFQuoteFieldsFragmentToQuoteContent(
  content: GatsbyTypes.ACFQuoteFieldsFragment
): QuoteContent {
  if (!content.content) {
    throw new MissingRequiredProperty()
  }

  return {
    id: uuidv4(),
    type: ContentType.Quote,
    layout: ContentLayout.Wide,
    content: content.content,
    hasBackgroundColor: !!content.backgroundColor,
    ...(content.caption ? { caption: content.caption } : {}),
  }
}

export function mapACFTextFieldsFragmentToTextContent(
  content: GatsbyTypes.ACFTextFieldsFragment
): TextContent {
  if (!content.layout) {
    throw new MissingRequiredProperty()
  }

  const layout = mapContentLayout(content.layout)

  if (layout === ContentLayout.Fullscreen) {
    throw new InvalidProperty()
  }

  if (!content.position) {
    throw new MissingRequiredProperty()
  }

  if (!content.content) {
    throw new MissingRequiredProperty()
  }

  return {
    id: uuidv4(),
    type: ContentType.Text,
    layout: layout,
    position: mapContentPosition(content.position),
    title: content.title,
    content: content.content,
  }
}

export function mapContentLayout(content: string): ContentLayout {
  if (content === ContentLayout.Column) {
    return ContentLayout.Column
  } else if (content === ContentLayout.Fullscreen) {
    return ContentLayout.Fullscreen
  }

  return ContentLayout.Wide
}

export function mapContentPosition(content: string): ContentPosition {
  return content === ContentPosition.Right
    ? ContentPosition.Right
    : ContentPosition.Left
}

type ACFFieldsFragment =
  | GatsbyTypes.ACFTextFieldsFragment
  | GatsbyTypes.ACFQuoteFieldsFragment
  | GatsbyTypes.ACFVideoFieldsFragment
  | GatsbyTypes.ACFImageFieldsFragment

export function isACFImageFieldsFragment(
  ACFFieldsFragment: ACFFieldsFragment
): ACFFieldsFragment is GatsbyTypes.ACFImageFieldsFragment {
  return ACFFieldsFragment.fieldGroupName === 'post_Acf_Content_Image'
}

export function isACFVideoFieldsFragment(
  ACFFieldsFragment: ACFFieldsFragment
): ACFFieldsFragment is GatsbyTypes.ACFVideoFieldsFragment {
  return ACFFieldsFragment.fieldGroupName === 'post_Acf_Content_Video'
}

export function isACFQuoteFieldsFragment(
  ACFFieldsFragment: ACFFieldsFragment
): ACFFieldsFragment is GatsbyTypes.ACFQuoteFieldsFragment {
  return ACFFieldsFragment.fieldGroupName === 'post_Acf_Content_Quote'
}

export function isACFTextFieldsFragment(
  ACFFieldsFragment: ACFFieldsFragment
): ACFFieldsFragment is GatsbyTypes.ACFTextFieldsFragment {
  return ACFFieldsFragment.fieldGroupName === 'post_Acf_Content_Text'
}

import { FluidObject } from 'gatsby-image'

export enum ContentType {
  Image,
  Video,
  Quote,
  Text,
}

export enum ContentLayout {
  Column = 'column',
  Wide = 'wide',
  Fullscreen = 'fullscreen',
}

export enum ContentPosition {
  Left = 'left',
  Right = 'right',
}

export type Image = {
  title?: string
  description?: string
  caption?: string
  fluid: FluidObject
}

export interface BaseProject {
  id: string
  title: string
  color: string
  description: string
  date: string
  directors: string[]
  authors: string[]
  order: number
  slug: string
  roles: string[]
  thumbnail: Image
}

export interface Project extends BaseProject {
  properties: Property[]
  content: Content[]
  next?: BaseProject
}

export type Property = {
  name: string
  values: string | string[]
}

export type BaseContent = {
  id: string
  type: ContentType
  layout: ContentLayout
  position: ContentPosition
  displayProjectName?: boolean
}

export type Content = ImageContent | VideoContent | QuoteContent | TextContent

export interface TextContent extends BaseContent {
  title?: string
  content: string
  layout: ContentLayout.Column | ContentLayout.Wide
  position: ContentPosition.Left | ContentPosition.Right
}

export interface ImageContent extends BaseContent {
  type: ContentType.Image
  layout: ContentLayout
  position: ContentPosition
  image: Image
  hasDescription: boolean
  projectLetters?: string
}

export interface QuoteContent extends Omit<BaseContent, 'position'> {
  type: ContentType.Quote
  content: string
  caption?: string
  hasBackgroundColor: boolean
}

export interface VideoContent extends Omit<BaseContent, 'position'> {
  type: ContentType.Video
  video: string
}

export interface Author {
  url: string
  name: string
}

export interface Contact {
  label: string
  email: string
}

import React from 'react'
import styled from 'styled-components'
// @ts-expect-error: No types available
import Link from 'gatsby-plugin-transition-link'

import { BaseProject } from '../@types/core'
import { computeProjectPathname } from '../utils'

import Order from './order'
import Image from './image'
import SafeHTML from './safe-html'
import NestedList from './nested-list'

type Props = {
  className?: string
  project: BaseProject
  index: number
}

const Preview: React.FC<Props> = ({ className, project, index }) => (
  <Container
    className={className}
    to={computeProjectPathname(project.slug)}
    title={project.title}
  >
    <ImageWrapper>
      <Image
        title={project.thumbnail.title}
        fluid={project.thumbnail.fluid}
        loading="eager"
      ></Image>
    </ImageWrapper>
    <Header>
      <StyledOrder order={index} />
      {project.roles.length && (
        <Roles>
          <NestedList items={project.roles.map((role) => ({ name: role }))} />
        </Roles>
      )}
      {project.title && <Title type="h2" content={project.title} />}
    </Header>
  </Container>
)

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 180%; /* 0.54 ratio */
  }
  > .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Container = styled(Link)`
  position: relative;
  display: grid;
  grid-row-gap: 1.7rem;
  grid-template-rows: 1fr 90px;
  width: 100%;
  height: 100%;
  color: var(--black-color);
  background-color: var(--white-color);
  text-decoration: none;

  @media (hover) {
    ${ImageWrapper} {
      filter: contrast(1.2);
      transition: filter 1s cubic-bezier(0, 0.55, 0.45, 1);
    }

    &:hover {
      ${ImageWrapper} {
        filter: contrast(1.2);

        .gatsby-image-wrapper {
          transform: scale(1.2);
          transition-duration: 30s;
        }
      }
    }
    ${ImageWrapper} {
      .gatsby-image-wrapper {
        transition: transform 1s linear;
      }
    }
  }
`

const Header = styled.header`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: 40px 1fr;
  grid-gap: 1rem 10px;
  align-items: flex-end;
  margin: 0;
`

const Title = styled(SafeHTML)`
  grid-column: 1/ -1;
  font-weight: 500;
  font-size: 0.88rem;
  line-height: 0.94rem;
  margin: 0;
  white-space: normal;
`

const StyledOrder = styled(Order)`
  font-size: 2.66rem;
  line-height: 2.22rem;
`

const Roles = styled.div`
  text-align: right;
`

export default Preview

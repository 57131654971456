import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  order: number
}

const Order: React.FC<Props> = ({ className, order }) => (
  <Container className={className}>
    <Number>{formatOrder(order)}</Number>
  </Container>
)

export default Order

const formatOrder = (index: number): string => index.toString().padStart(2, '0')

const Container = styled.div`
  &:after {
    content: '.';
  }
`

const Number = styled.span`
  font-weight: bold;
`
